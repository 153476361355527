import React, { useEffect, useState, Dispatch, FC, SetStateAction } from 'react'

import { PlusIcon, MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import { Box, Dialog, DialogActions, DialogTitle } from '@mui/material'
import { useMediaQuery, useDebounceValue } from 'usehooks-ts'

import { SearchField } from 'src/components/Library/SearchField'
import Tab from 'src/components/Library/Tab'
import Tabs from 'src/components/Library/Tabs'

import IconButton from '../../IconButton/IconButton'
import Button from '../../Library/Button'
import AnnouncementList from '../AnnouncementList/AnnouncementList'
import type { AnnouncerEventCellItemType } from '../AnnouncementsCell'

enum TabType {
  ThisMonth = 'This Month',
  Future = 'Future',
  Completed = 'Completed',
}

interface AnnouncementTabsProps {
  events: AnnouncerEventCellItemType[]
  selectedEvent: AnnouncerEventCellItemType
  setSelectedEvent: Dispatch<SetStateAction<AnnouncerEventCellItemType>>
  isProcessing: boolean
}

const AnnouncementTabs: FC<AnnouncementTabsProps> = ({
  events,
  selectedEvent,
  setSelectedEvent,
  isProcessing,
}) => {
  const [activeTab, setActiveTab] = useState<TabType>(TabType.ThisMonth)
  const isMobile = useMediaQuery('(max-width: 640px)')
  const [isSearchOpen, setSearchOpen] = useState(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [debouncedSearchValue] = useDebounceValue<string>(searchValue, 500)
  const [filteredEvents, setFilteredEvents] = useState<
    AnnouncerEventCellItemType[]
  >([])
  const [isSearching, setIsSearching] = useState(false)

  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1)

  // Helper function to get events by tab
  const getEventsByTab = (tab: TabType): AnnouncerEventCellItemType[] => {
    switch (tab) {
      case TabType.ThisMonth:
        return events.filter(
          (event) =>
            event.endAt &&
            event.startAt &&
            new Date(event.endAt) >= today &&
            new Date(event.startAt) < nextMonth,
        )
      case TabType.Future:
        return events.filter(
          (event) => event.startAt && new Date(event.startAt) >= nextMonth,
        )
      case TabType.Completed:
        return events.filter(
          (event) => event.endAt && new Date(event.endAt) < today,
        )
      default:
        return []
    }
  }

  // Helper function to handle search
  const handleSearch = (
    value: string,
    eventsToFilter: AnnouncerEventCellItemType[],
  ) => {
    if (!value) {
      setIsSearching(false)
      return eventsToFilter
    }
    setIsSearching(true)
    const searchValue = value.toLowerCase()
    return eventsToFilter.filter(
      (event) =>
        (event.eventBody.toLowerCase().includes(searchValue) ?? false) ||
        (event.eventLink.toLowerCase().includes(searchValue) ?? false),
    )
  }

  // Function to update the filtered events based on tab and search value
  const updateFilteredEvents = (value: string): void => {
    const eventsToFilter = getEventsByTab(activeTab)
    const filtered = handleSearch(value, eventsToFilter)
    setFilteredEvents(filtered)
  }

  // Search click handler for mobile view
  const searchClick = () => {
    updateFilteredEvents(searchValue)
    closeSearchModal()
  }

  // Update filtered events automatically on desktop or when search value changes
  useEffect(() => {
    if (!isMobile) {
      updateFilteredEvents(debouncedSearchValue)
    }
  }, [debouncedSearchValue, activeTab, events?.length])

  // Reset events immediately when search value is cleared
  useEffect(() => {
    if (searchValue === '') {
      updateFilteredEvents('') // Reset the list immediately when the search is cleared
    }
  }, [searchValue, activeTab])

  // if the events change, update the filtered events
  useEffect(() => {
    setFilteredEvents(getEventsByTab(activeTab))
  }, [events])

  // Handle opening and closing of the search modal
  const openSearchModal = () => setSearchOpen(true)
  const closeSearchModal = () => setSearchOpen(false)

  const createEmptyEvent = () => ({
    id: 0,
    clientId: 0,
    createdAt: '',
    isActive: false,
    membershipGroups: [],
    roles: [],
    startAt: undefined,
    endAt: undefined,
  })

  return (
    <div className="relative flex h-full flex-col gap-4">
      <div className="flex flex-row justify-between bg-white">
        {/* Tabs for This Month, Future, Completed */}
        <Tabs
          value={activeTab}
          onChange={(_, newValue) => setActiveTab(newValue as TabType)}
          className="sticky top-0 flex !border-0 bg-white"
        >
          <Tab
            label="This Month"
            value={TabType.ThisMonth}
            className="px-2 sm:px-6"
          />
          <Tab label="Future" value={TabType.Future} className="px-2 sm:px-6" />
          <Tab
            label="Completed"
            value={TabType.Completed}
            className="px-2 sm:px-6"
          />
        </Tabs>
        {/* Search and Create buttons */}
        <div className="mr-1 flex items-center">
          {isMobile ? (
            <>
              <IconButton
                color="primary"
                className="h-5 w-5"
                onClick={openSearchModal}
              >
                <MagnifyingGlassIcon className="h-5 w-5" />
              </IconButton>
              <IconButton
                color="primary"
                className="h-6 w-6"
                onClick={() => setSelectedEvent(createEmptyEvent())}
                disabled={isProcessing}
              >
                <PlusIcon className="h-6 w-6" />
              </IconButton>
            </>
          ) : (
            <div className="flex space-x-2 p-2">
              <SearchField value={searchValue} onChange={setSearchValue} />
              <Button
                onClick={() => setSelectedEvent(createEmptyEvent())}
                disabled={isProcessing}
                startIcon={<PlusIcon className="h-4 w-4" />}
                fullWidth={false}
                className="min-w-[100px]"
              >
                Create
              </Button>
            </div>
          )}
        </div>
      </div>

      {/* Render the AnnouncementList based on active tab */}
      <AnnouncementList
        events={filteredEvents}
        selectedEvent={selectedEvent}
        setSelectedEvent={setSelectedEvent}
        isProcessing={isProcessing}
        isSearching={isSearching}
      />

      {/* Search modal for mobile */}
      {isSearchOpen && (
        <Dialog open={isSearchOpen} onClose={closeSearchModal} fullWidth>
          <DialogTitle>Search Announcements</DialogTitle>
          <Box>
            <div className="grid w-full gap-4 p-4">
              <SearchField
                value={searchValue}
                onChange={setSearchValue}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
              />
            </div>
          </Box>
          <DialogActions>
            <Button
              color="secondary"
              onClick={() => setSearchValue('')}
              fullWidth={false}
            >
              Clear
            </Button>
            <Button onClick={searchClick} fullWidth={false}>
              Search
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  )
}

export default AnnouncementTabs
