import { Metadata } from '@redwoodjs/web'

import AnnouncementLayout from 'src/components/Announcements/AnnouncementLayout/AnnouncementLayout'

const SettingsAnnouncementsPage = () => {
  return (
    <>
      <Metadata
        title="Settings - Announcements"
        description="Settings - Announcements"
      />
      <AnnouncementLayout />
    </>
  )
}

export default SettingsAnnouncementsPage
