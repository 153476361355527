import { useEffect, useState } from 'react'

import { MegaphoneIcon, PlusIcon } from '@heroicons/react/24/outline'
import { InputLabel, Modal, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { PROD_CLIENT } from 'api/src/common/enums'
import dayjs from 'dayjs'
import {
  CreateAnnouncerEventMutation,
  CreateAnnouncerEventMutationVariables,
  DeleteAnnouncerEvent,
  DeleteAnnouncerEventVariables,
  MembershipRole,
  TableName,
  UpdateAnnouncerEventMutation,
  UpdateAnnouncerEventMutationVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { QUERY as AnnouncementsQuery } from 'src/components/Announcements/AnnouncementsCell/AnnouncementsCell'
import AnnouncerBar from 'src/components/Announcements/AnnouncerBar'
import Autocomplete from 'src/components/Library/Autocomplete/Autocomplete'
import Button from 'src/components/Library/Button'
import useACL from 'src/lib/hooks/ACL/useACL'
import { useAuth } from 'web/src/Providers'

const AnnouncementPanel = ({
  event,
  setSelectedEvent,
  isProcessing,
  setIsProcessing,
  isMobile,
  handleDrawerOpen,
  mobileDrawerOpen,
}) => {
  // move this logic to service
  const { currentUser } = useAuth()
  const clientId = currentUser.parentData.id
  const canSetGlobal =
    currentUser.roles.includes('SUPERADMIN') &&
    clientId === PROD_CLIENT.STAFFLINK

  const allRoles: MembershipRole[] = ['OWNER', 'ADMIN', 'EDITOR', 'MEMBER']

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [enteredTitle, setEnteredTitle] = useState(event ? event.eventBody : '')
  const [enteredLink, setEnteredLink] = useState(event ? event.eventLink : '')
  const [enteredButtonText, setEnteredButtonText] = useState(
    event ? event.eventButtonText : '',
  )
  const [enteredStart, setEnteredStart] = useState(event ? event.startAt : '')
  const [enteredEnd, setEnteredEnd] = useState(event ? event.endAt : '')
  const [enteredRoles, setEnteredRoles] = useState(event ? event.roles : '')
  const [titleError, setTitleError] = useState(null)
  const [rolesError, setRolesError] = useState(null)
  const [dateError, setDateError] = useState(null)

  // TODO this is not set so always false, is this needed anymore?
  const [itemIsGlobal, setItemIsGlobal] = useState(
    event ? event.isGlobal : false,
  )

  useEffect(() => {
    const today = new Date()
    if (event?.id !== 0) {
      setEnteredTitle(event ? event.eventBody : '')
      setEnteredLink(event ? event.eventLink : '')
      setEnteredButtonText(event ? event.eventButtonText : '')
      setEnteredStart(event ? event.startAt : today)
      setEnteredEnd(event ? event.endAt : today)
      setEnteredRoles(event ? event.roles : allRoles)
    } else {
      setEnteredTitle('')
      setEnteredLink('')
      setEnteredButtonText('')
      setEnteredStart(today)
      setEnteredEnd(today)
      setEnteredRoles(allRoles)
    }
    setTitleError(null)
    setRolesError(null)
    setDateError(null)
  }, [event])

  const { AccessControlList, saveACL, deleteACL } = useACL({
    resourceType: 'AnnouncerEvent' as TableName,
    resourceId: event?.id !== 0 && event !== null ? event.id : null,
    principalTypes: ['MEMBERSHIP', 'MEMBERSHIPGROUP'],
  })

  const fixUrl = (url: string) => {
    if (url && !url.match(/^(http|https):/)) {
      return 'https://' + url
    }
    return url
  }

  const makeEndOfDay = (date: Date) => {
    let newDate: Date
    if (!date) {
      newDate = new Date()
    } else {
      newDate = new Date(date)
    }
    newDate = dayjs(newDate).endOf('day').toDate()

    return newDate
  }

  const makeStartOfDay = (date: Date) => {
    let newDate: Date
    if (!date) {
      newDate = new Date()
    } else {
      newDate = new Date(date)
    }

    newDate = dayjs(newDate).startOf('day').toDate()

    return newDate
  }

  const CREATE_ANNOUNCER_ITEM = gql`
    mutation CreateAnnouncerEventMutation($input: CreateAnnouncerEventInput!) {
      createAnnouncerEvent(input: $input) {
        id
        clientId
        eventBody
        eventLink
        eventButtonText
        startAt
        endAt
        isGlobal
        roles
      }
    }
  `

  const [createAnnouncerItemMutation] = useMutation<
    CreateAnnouncerEventMutation,
    CreateAnnouncerEventMutationVariables
  >(CREATE_ANNOUNCER_ITEM, {
    onCompleted: async (item) => {
      const newData = item.createAnnouncerEvent

      if (!itemIsGlobal) {
        await saveACL({ resourceId: newData.id })
      }
      if (mobileDrawerOpen) {
        handleDrawerOpen()
      }
      setIsProcessing(false)
      setSelectedEvent(null)
    },
    refetchQueries: [
      {
        query: AnnouncementsQuery,
        variables: {
          clientId: currentUser.membershipData.clientId,
        },
      },
    ],
  })

  async function createAnnouncerItem(args) {
    await createAnnouncerItemMutation({
      variables: {
        input: {
          clientId: currentUser.membershipData.clientId,
          isActive: true,
          eventBody: args.data.eventBody,
          eventLink: args.data.eventLink,
          eventButtonText: args.data.eventButtonText,
          startAt: args.data.startAt,
          endAt: args.data.endAt,
          isGlobal: false,
          roles: args.data.roles,
        },
      },
    })
  }

  const UPDATE_ANNOUNCER_ITEM = gql`
    mutation UpdateAnnouncerEventMutation(
      $input: UpdateAnnouncerEventInput!
      $id: Int!
    ) {
      updateAnnouncerEvent(input: $input, id: $id) {
        id
        clientId
        eventBody
        eventLink
        eventButtonText
        startAt
        endAt
        isAll
        isGlobal
        roles
      }
    }
  `

  const [updateAnnouncerItemMutation] = useMutation<
    UpdateAnnouncerEventMutation,
    UpdateAnnouncerEventMutationVariables
  >(UPDATE_ANNOUNCER_ITEM, {
    onCompleted: async (item) => {
      const newData = item.updateAnnouncerEvent
      if (!itemIsGlobal) {
        await saveACL({ resourceId: newData.id })
      }
      setIsProcessing(false)
      if (mobileDrawerOpen) {
        handleDrawerOpen()
      }
      setSelectedEvent(null)
    },
    refetchQueries: [
      {
        query: AnnouncementsQuery,
        variables: {
          clientId: currentUser.membershipData.clientId,
        },
      },
    ],
  })

  async function updateAnnouncerItem(args) {
    await updateAnnouncerItemMutation({
      variables: {
        id: args.data.id,
        input: {
          eventBody: args.data.eventBody,
          eventLink: args.data.eventLink,
          eventButtonText: args.data.eventButtonText,
          startAt: args.data.startAt,
          endAt: args.data.endAt,
          isGlobal: args.data.isGlobal,
          roles: args.data.roles,
        },
      },
    })
  }

  const DELETE_ANNOUNCER_ITEM = gql`
    mutation DeleteAnnouncerEvent($id: Int!) {
      deleteAnnouncerEvent(id: $id) {
        id
      }
    }
  `

  const [deleteAnnouncerItemMutation] = useMutation<
    DeleteAnnouncerEvent,
    DeleteAnnouncerEventVariables
  >(DELETE_ANNOUNCER_ITEM, {
    onCompleted: async (item) => {
      const announcerId = item.deleteAnnouncerEvent.id
      await deleteACL({ resourceId: announcerId })
      setDeleteModalOpen(false)
      setDeleting(false)
      setIsProcessing(false)
      if (mobileDrawerOpen) {
        handleDrawerOpen()
      }
      setSelectedEvent(null)
    },
    refetchQueries: [
      {
        query: AnnouncementsQuery,
        variables: {
          clientId: currentUser.membershipData.clientId,
        },
      },
    ],
  })

  async function deleteAnnouncerItem(args) {
    await deleteAnnouncerItemMutation({
      variables: {
        id: args.data.id,
      },
    })
  }

  const handleDelete = (args) => {
    if (args.isGlobal && !canSetGlobal) {
      toast.error('Only Superadmins can delete global announcements!', {
        duration: 5000,
      })
    } else {
      deleteAnnouncerItem({ data: args })
    }
  }

  const handleCreate = () => {
    createAnnouncerItem({
      data: {
        eventBody: enteredTitle,
        eventLink: fixUrl(enteredLink),
        eventButtonText: enteredButtonText,
        startAt: makeStartOfDay(enteredStart),
        endAt: makeEndOfDay(enteredEnd),
        isGlobal: false,
        roles: enteredRoles,
      },
    })
  }

  const handleUpdate = () => {
    updateAnnouncerItem({
      data: {
        id: event.id,
        eventBody: enteredTitle,
        eventLink: fixUrl(enteredLink),
        eventButtonText: enteredButtonText,
        startAt: makeStartOfDay(enteredStart),
        endAt: makeEndOfDay(enteredEnd),
        isGlobal: false,
        roles: enteredRoles,
      },
    })
  }

  const handleSave = () => {
    let errorFound = false
    if (enteredTitle === '' || enteredTitle === null) {
      setTitleError('Title is required')
      errorFound = true
    } else {
      setTitleError(null)
    }
    if (enteredRoles.length === 0) {
      setRolesError('Roles are required')
      errorFound = true
    } else {
      setRolesError(null)
    }
    if (enteredStart > enteredEnd) {
      setDateError('Start date must be before end date')
      errorFound = true
    } else {
      setDateError(null)
    }
    //check that dates are populated
    if (
      enteredStart === '' ||
      enteredEnd === '' ||
      enteredStart === null ||
      enteredEnd === null
    ) {
      setDateError('Start and End dates are required')
      errorFound = true
    }
    if (errorFound) {
      return
    }
    setIsProcessing(true)
    if (event.id === 0) {
      handleCreate()
    } else {
      handleUpdate()
    }
  }

  if (!event && !isMobile) {
    return (
      <div className="flex items-center h-full flex-col gap-4 px-12 pt-48">
        <p className="text-gray-500 text-xl">Nothing Selected</p>
        <MegaphoneIcon className="text-gray-500 w-36 h-36" />
        <Button className="w-full" onClick={() => setSelectedEvent({ id: 0 })}>
          <PlusIcon className="text-white h-4 w-4 mr-1" /> Create Announcement
        </Button>
      </div>
    )
  }

  return (
    <div className="flex flex-col overflow-auto">
      <div className="border-b-2 border-gray-300 p-4 flex gap-4 items-center">
        <div>
          <MegaphoneIcon className="text-white w-8 h-8 bg-indigo-500 rounded" />
        </div>
        <h2 className="text-xl truncate">
          {event?.id !== 0 ? `Editing ${event?.eventBody}` : 'New Announcement'}
        </h2>
      </div>
      <div className="p-4 flex flex-col gap-6 border-b-2 border-gray-300 overflow-auto max-h-[calc(100vh-350px)]">
        <div>
          <div className="flex justify-between w-full">
            <InputLabel id="event-title">Title</InputLabel>
            {titleError && <p className="text-red-500 text-sm">{titleError}</p>}
          </div>
          <TextField
            id="event-title"
            type="text"
            placeholder={''}
            fullWidth
            size="small"
            value={enteredTitle}
            onChange={(e) => setEnteredTitle(e.target.value)}
            error={titleError}
          />
        </div>
        <div>
          <InputLabel id="event-link">Link</InputLabel>
          <TextField
            type="text"
            placeholder={''}
            fullWidth
            size="small"
            value={enteredLink}
            onChange={(e) => setEnteredLink(e.target.value)}
          />
        </div>
        <div>
          <InputLabel id="event-text">Button Text</InputLabel>
          <TextField
            type="text"
            placeholder={''}
            fullWidth
            size="small"
            value={enteredButtonText}
            onChange={(e) => setEnteredButtonText(e.target.value)}
          />
        </div>
        {dateError && <p className="text-red-500 text-sm">{dateError}</p>}
        <div className="flex gap-4">
          <div className="flex-grow">
            <InputLabel id="event-start">Start Date</InputLabel>
            <DatePicker
              className="w-full"
              value={dayjs(enteredStart)}
              onChange={(date) => setEnteredStart(date)}
            />
          </div>
          <div className="flex-grow">
            <InputLabel id="event-end">End Date</InputLabel>
            <DatePicker
              className="w-full"
              value={dayjs(enteredEnd)}
              onChange={(date) => setEnteredEnd(date)}
            />
          </div>
        </div>
        <div>
          <div className="flex justify-between w-full">
            <InputLabel id="event-roles">Roles</InputLabel>
            {rolesError && <p className="text-red-500 text-sm">{rolesError}</p>}
          </div>
          <Autocomplete
            className="w-full bg-white"
            fullWidth
            size="small"
            value={enteredRoles}
            disableCloseOnSelect
            filterSelectedOptions
            multiple
            onChange={(_event, newValue) => {
              setEnteredRoles(newValue)
            }}
            id="event-roles"
            options={allRoles}
            renderInput={(params) => (
              <TextField
                {...params}
                style={
                  rolesError
                    ? { border: '1px solid red', borderRadius: '6px' }
                    : {}
                }
              />
            )}
          />
        </div>
        <AccessControlList />
      </div>
      <p className="text-gray-600 p-4 border-b-2 border-gray-300">Preview</p>
      <div className="border-b-2 border-gray-300 py-2">
        <AnnouncerBar
          events={[
            {
              id: 1,
              clientId: 1,
              eventBody: enteredTitle,
              eventLink: enteredLink,
              eventButtonText: enteredButtonText,
              startAt: enteredStart,
              endAt: enteredEnd,
              isActive: true,
            },
          ]}
        />
      </div>
      <div className="p-4 flex gap-4 pt-8">
        {event?.id !== null && event?.id !== undefined && event?.id !== 0 && (
          <div>
            <Button
              className="bg-red-500 text-white hover:bg-red-600"
              onClick={() => setDeleteModalOpen(true)}
            >
              Delete Announcement
            </Button>
          </div>
        )}
        <div className="ml-auto flex gap-4">
          <Button
            className="bg-white text-gray-500 hover:bg-gray-100"
            onClick={() => (handleDrawerOpen(), setSelectedEvent(null))}
            disabled={isProcessing}
          >
            Cancel
          </Button>
          <Button onClick={() => handleSave()} loading={isProcessing}>
            Save
          </Button>
        </div>
      </div>
      <Modal open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
        <div className="flex h-full w-full items-center justify-center">
          <div className="bg-white rounded-lg p-4 w-96">
            <h2 className="text-xl font-bold pb-4">Delete Announcement</h2>
            <p>
              Are you sure you want to delete the{' '}
              <strong>{event?.eventBody}</strong> announcement?
            </p>
            <div className="flex gap-4 pt-8 ml-auto">
              <Button
                className="bg-white text-gray-500 hover:bg-gray-100"
                onClick={() => (setDeleteModalOpen(false), handleDrawerOpen())}
                disabled={isProcessing}
              >
                Cancel
              </Button>
              <Button
                className="bg-red-500 text-white hover:bg-red-600"
                loading={deleting}
                onClick={() => {
                  setDeleting(true)
                  setIsProcessing(true)
                  handleDelete(event)
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default AnnouncementPanel
