import type { Dispatch, FC, SetStateAction } from 'react'

import type {
  FindAnnouncementTableQuery,
  FindAnnouncementTableQueryVariables,
} from 'types/graphql'

import { CellSuccessProps, type CellFailureProps } from '@redwoodjs/web'

import AnnouncementTabs from 'src/components/Announcements/AnnouncementTabs/AnnouncementTabs'
import { default as LoadingSpinner } from 'src/components/Library/Loading'

export const QUERY = gql`
  query FindAnnouncementTableQuery {
    announcerEvents: announcerEventsByClient {
      id
      clientId
      eventBody
      eventLink
      startAt
      endAt
      eventButtonText
      isGlobal
      roles
      createdAt
      isActive
      membershipGroups {
        announcerEventId
      }
    }
  }
`

export const Loading = () => <LoadingSpinner />

export const Empty = ({ selectedEvent, setSelectedEvent, isProcessing }) => (
  <AnnouncementTabs
    events={[]}
    selectedEvent={selectedEvent}
    setSelectedEvent={setSelectedEvent}
    isProcessing={isProcessing}
  />
)

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error.message}</div>
)

interface AnnouncementCellProps {
  selectedEvent: AnnouncerEventCellItemType
  setSelectedEvent: Dispatch<SetStateAction<AnnouncerEventCellItemType>>
  isProcessing: boolean
}

type SuccessProps = CellSuccessProps<
  FindAnnouncementTableQuery,
  FindAnnouncementTableQueryVariables
> &
  AnnouncementCellProps

export type AnnouncerEventCellItemType =
  FindAnnouncementTableQuery['announcerEvents'][0]

export const Success: FC<SuccessProps> = ({
  announcerEvents,
  selectedEvent,
  setSelectedEvent,
  isProcessing,
}) => {
  return (
    <AnnouncementTabs
      events={announcerEvents}
      selectedEvent={selectedEvent}
      setSelectedEvent={setSelectedEvent}
      isProcessing={isProcessing}
    />
  )
}
