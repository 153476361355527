import type { Dispatch, FC, SetStateAction } from 'react'

import { ChevronRightIcon } from '@heroicons/react/24/solid'

import type { AnnouncerEventCellItemType } from '../AnnouncementsCell'

interface AnnouncementItemProps {
  event: AnnouncerEventCellItemType
  selectedEvent: AnnouncerEventCellItemType
  setSelectedEvent: Dispatch<SetStateAction<AnnouncerEventCellItemType>>
  isProcessing: boolean
}

const AnnouncementItem: FC<AnnouncementItemProps> = ({
  event,
  selectedEvent,
  setSelectedEvent,
  isProcessing,
}) => {
  const isSelected = selectedEvent?.id === event.id
  const startAtDate = new Date(event?.startAt)
  const endAtDate = new Date(event?.endAt)

  return (
    <button
      className={` border-gray-200 border-2 p-2 rounded-lg flex items-center hover:cursor-pointer ${
        isSelected ? 'border-indigo-500 bg-indigo-100' : 'bg-white'
      } ${isProcessing ? 'opacity-50' : ''}`}
      onClick={() => setSelectedEvent(event)}
      disabled={isProcessing}
    >
      <div className="flex items-center gap-3 truncate">
        {(event?.startAt || event?.endAt) && (
          <>
            <div className="flex-grow">
              <p className="text-xl md:text-3xl text-indigo-600 border-r-2 border-gray-400 w-20 md:w-36">
                {startAtDate.getDate()} - {endAtDate.getDate()}
              </p>
            </div>
            <div>
              <p className="text-gray-500 border-r-2 border-gray-400 pr-3 uppercase w-24 flex-grow">
                {startAtDate?.toLocaleString('en-AU', { month: 'short' })}
                {', '}
                {startAtDate?.toLocaleString('en-AU', {
                  weekday: 'short',
                })}
              </p>
            </div>
          </>
        )}
        <p className="text-xl text-gray-700 truncate flex-shrink">
          {event.eventBody}
        </p>
      </div>
      <div className="ml-auto flex gap-4">
        <ChevronRightIcon className="text-gray-500 w-5 h-5" />
      </div>
    </button>
  )
}

export default AnnouncementItem
